export const AnalyticsEvents = {
  homepage: {
    data: {
      eventCategory: "insure24_homepage",
      pageUrl: "https://www.insure24.com",
      event: "custom_event_name",
    },
    login_staticpage: {
      name: "login_staticpage",
    },
    whatsapp_chat_staticpage: {
      name: "whatsapp_chat_staticpage",
    },
    get_car_quote_staticpage_header: {
      name: "get_car_quote_staticpage_header",
    },
    get_bike_quote_staticpage_header: {
      name: "get_bike_quote_staticpage_header",
    },
    get_car_quote_staticpage_card: {
      name: "get_car_quote_staticpage_card",
    },
    health_guard_buynow_staticpage: {
      name: "health_guard_buynow_staticpage",
    },
    get_bike_quote_staticpage_card: {
      name: "get_bike_quote_staticpage_card",
    },
    get_car_quote_staticpage_footer: {
      name: "get_car_quote_staticpage_footer",
    },
    get_bike_quote_staticpage_footer: {
      name: "get_bike_quote_staticpage_footer",
    },
    get_health_quote_staticpage: {
      name: "get_health_quote_staticpage",
    },
    explore_now_staticpage: {
      name: "explore_now_staticpage",
    },
    card_buynow_staticpage: {
      name: "card_buynow_staticpage",
    },
    mobile_buynow_staticpage: {
      name: "mobile_buynow_staticpage",
    },
    upi_buynow_staticpage: {
      name: "upi_buynow_staticpage",
    },
    click_Insurenow_staticpage: {
      name: "click_Insurenow_staticpage",
    },
    about_us_staticpage: {
      name: "about_us_staticpage",
    },
    car_assitance_buy_now_staticpage: {
      name: "car_assitance_buy_now_staticpage",
    },
    claims_staticpage: {
      name: "claims_staticpage",
    },
    blogs_staticpage: {
      name: "blogs_staticpage",
    },
    click_promotional_banner: {
      name: "click_promotional_banner",
    },
  },
};

// eventCategory: "insure24_homepage",
