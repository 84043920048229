"use client";
import Image from "next/image";
import cars24Logo from "../../../public/images/homepage/cars24Logo.svg";
import styles from "./style.module.scss";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import whiteStar from "../../images/lotties/whiteStar.json";
import { Antonio } from "next/font/google";
import { HOME_PAGE_DATA } from "./constants";
import AppAnimatedImage from "@/components/app-animated-image";
import BannerImage from "../../images/lotties/home-banner.json";
import BannerLogo from "../../images/white-logo.svg";

const HomePageBannerVideos = dynamic(
  () => import("@/components/homePageBannerVideos"),
  {
    ssr: true,
  }
);
const ExploreButton = dynamic(
  () => import("@/components/homePageBanner/exploreButton"),
  {
    ssr: false,
    loading: () => <div className={styles.exploreButtonLoader} />,
  }
);

const antonio = Antonio({
  weight: ["400"], // Specify the weights you need
  subsets: ["latin"], // Define the subset (e.g., 'latin')
});

export const HomePageBanner = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setTimeout(() => {
        setIsMobile(window.innerWidth < 1024);
      }, 2500);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div>
      <div className={styles.pageBannerContent}>
        <AppAnimatedImage
          image={BannerImage}
          className={styles.animatedBannerImg}
          height="100%"
          width="100%"
          loop={true}
        />
        <div className={styles.bannerData}>
          <div className={styles.logoSection}>
            <div className={styles.subLogo}>
              <Image
                className={styles.cars24Logo}
                src={cars24Logo}
                alt="Cars24 Logo"
              />
            </div>
            <div className={styles.logoText}>{HOME_PAGE_DATA.title}</div>
            <div className={styles.logo}>
              <Image src={BannerLogo} alt="Loans24 Logo" />
              <div className={`${styles.quote} ${antonio.className}`}>
                {HOME_PAGE_DATA.tag}
                <div className={styles.whiteStar}>
                  <AppAnimatedImage
                    image={whiteStar}
                    className={styles.bannerStarImg}
                    width={"34px"}
                    height="34px"
                  />
                </div>
              </div>
            </div>
          </div>
          <ExploreButton />
          <HomePageBannerVideos />
        </div>
      </div>
    </div>
  );
};
