export const HOME_PAGE_DATA = {
  title: "PRESENTS",
  tag: "YOU LOVE IT, WE INSURE IT",
};

export const BANNER_VIDEOS_STATIC_QUOTE = {
  title:
    "We’re all set to become India's top insurance marketplace, making it easy for customers to compare, select, and buy insurance. Our focus is on simplifying the process so everyone can choose the right coverage for their needs.",
  name: "Vikram Chopra",
  position: "Founder & CEO, CARS24",
};
