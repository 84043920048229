"use client";
import styles from "./style.module.scss";
import { Navbar } from "@/components/navbar";
import React from "react";
import dynamic from "next/dynamic";
import WhyInsurance from "@/components/whyInsure";
import ScrollText from "@/components/scrollText";
import { MobileNavbar } from "@/components/mobileNavbar";
import Testimonial from "@/components/testimonial";
import ParentStyles from "../../styles/layouts.module.scss";
import OurPartners from "@/components/our-partners";
import MotorInsurance from "@/components/motor-insurance";
import FaqBlock from "@/components/faq-block";
import BuyInsuranceCard from "@/components/buy-insurance-card";
import { HomePageBanner } from "../../components/homePageBanner";
import { ChatIcon } from "@/components/chat-icon";
import mahakumbhBannerImageDesktop from "../../../public/images/homepage/mahakumbh-desktop.png";
import mahakumbhBannerImageMobile from "../../../public/images/homepage/mahakumbh-mobile.png";
import PromototionalBanner from "@/components/promotional-banner";
import { EXTERNAL_URLS } from "@/constants/urls.constants";

const Footer = dynamic(() => import("@/components/footer"), {
  ssr: false,
});
const StayConnected = dynamic(() => import("@/components/stay-connected"), {
  ssr: true,
});
const PocketInsurance = dynamic(() => import("@/components/pocket-insurance"), {
  ssr: false,
  loading: () => <div className={styles.pocketInsuranceLoader} />,
});

export const HomePage = () => {
  return (
    <div>
      <div className={styles.instantSection}>
        <div className={ParentStyles.mView}>
          <MobileNavbar />{" "}
        </div>
        <div className={ParentStyles.dView}>
          <Navbar />
        </div>
        <div className={styles.chatIcon}>
          <ChatIcon />
        </div>
        <HomePageBanner />
      </div>
      <PromototionalBanner
        promotionalBannerDesktop={mahakumbhBannerImageDesktop}
        promotionalBannerMobile={mahakumbhBannerImageMobile}
        url={EXTERNAL_URLS.MAHAKUMBH_PROMOTIONAL_BANNER}
      />
      <MotorInsurance />
      <PocketInsurance />
      <WhyInsurance />
      <OurPartners />
      <ScrollText />
      <Testimonial />
      <BuyInsuranceCard />
      <StayConnected />
      <FaqBlock />
      <Footer />
    </div>
  );
};
