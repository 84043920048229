"use client";
import styles from "./styles.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import { PAGE_CONTENT } from "./constants";
import Image from "next/image";
import { Heading } from "@cars24/turbo-web/lib/heading";
import { Button } from "@cars24/turbo-web/lib/button";
import buyInsureSvg from "../../images/insure-vehicle.svg";
import { NavigateToLink } from "@/utils/navigation";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";

const BuyInsuranceCard = () => {
  return (
    <section
      className={`${styles.stayConnectedContainer} ${parentStyles.mViewLayout} ${parentStyles.appContentBlock}`}
    >
      <div className={`${styles.appPageBlock} ${parentStyles.appPageBlock}`}>
        <div className={`${styles.buyInsureWrapper}`}>
          <div className={`${styles.buyInsure}`}>
            <div className={`${styles.grideWrapper}`}>
              <div className={styles.titleBtn}>
                <Heading color="var(--base-white)" isBold level={2}>
                  {PAGE_CONTENT.title}
                </Heading>
               
                  <Button
                    label={"Insure now"}
                    onClick={() => {
                      sendAnalyticsData(
                        AnalyticsEvents.homepage.click_Insurenow_staticpage
                          .name,
                        AnalyticsEvents.homepage.data
                      );
                      window.clevertap.event.push(
                        AnalyticsEvents.homepage.click_Insurenow_staticpage.name
                      );
                      NavigateToLink(
                        EXTERNAL_URLS.CAR_INSURANCE 
                      );
                    }}
                    variant={"secondary"}
                  />
                
              </div>
              <div>
                <Image
                  src={buyInsureSvg}
                  alt="buyInsure"
                  className={styles.cardImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default BuyInsuranceCard;
