"use client";
import styles from "./style.module.scss";
import parentStyles from "../../styles/layouts.module.scss";
import Image from "next/image";
import { NavigateToLink } from "@/utils/navigation";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";

const PromototionalBanner = ({
  promotionalBannerDesktop,
  promotionalBannerMobile,
  url,
}) => {
  return (
    <section
      className={`${styles.promotionalBannerContainer} ${parentStyles.mViewLayout}`}
    >
      <div className={`${parentStyles.appPageBlock}`}>
        <div
          className={`${styles.promotionalBanner} ${styles.desktopView}`}
          onClick={() => {
            NavigateToLink(url);
            sendAnalyticsData(
              AnalyticsEvents.homepage.click_promotional_banner.name,
              {
                ...AnalyticsEvents.homepage.data,
                eventLabel: "maha_kumbh",
              }
            );
          }}
        >
          <Image
            src={promotionalBannerDesktop}
            alt="Description of image"
            layout="responsive"
            width={1024}
            height={187}
          />
        </div>
        <div
          className={`${styles.promotionalBanner} ${styles.mobileView}`}
          onClick={() => {
            NavigateToLink(url);
            sendAnalyticsData(
              AnalyticsEvents.homepage.click_promotional_banner.name,
              {
                ...AnalyticsEvents.homepage.data,
                eventLabel: "new_year",
              }
            );
          }}
        >
          <Image
            src={promotionalBannerMobile}
            alt="Description of image"
            layout="responsive"
            width={343}
            height={341}
          />
        </div>
      </div>
    </section>
  );
};
export default PromototionalBanner;
