import Image from "next/image";
import chatSvg from "../../../public/images/chat.svg";
import { getWhatsAppUrl } from "@/utils/getWhatsAppUrl";
import { sendAnalyticsData } from "@/services/google-analytics";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";

export const ChatIcon = () => {
  const onClickOpenWhatsApp = () => {
    sendAnalyticsData(
      AnalyticsEvents.homepage.whatsapp_chat_staticpage.name,
      AnalyticsEvents.homepage.data
    );
    const whatsappUrl = getWhatsAppUrl();
    window.open(whatsappUrl);
  };
  return (
    <Image
      src={chatSvg}
      alt="chat-icon"
      height={88}
      width={88}
      onClick={onClickOpenWhatsApp}
      style={{
        cursor: "pointer",
      }}
    />
  );
};
