"use client";
import { HomePage } from "@/containers/homepage";
import "./globals.css";
import { useEffect } from "react";
import { initializeAnalytics } from "@/services/google-analytics";
import { loadClevertap } from "@/services/clevertap-analytics";

export default function Home() {
  useEffect(() => {
    initializeAnalytics("GTM-TXDJT4J5");
    if (typeof window !== "undefined") {
      loadClevertap("48K-4W7-8Z7Z", () => {});
    }
  }, []);
  return (
    <div>
      <HomePage />
    </div>
  );
}
