"use client";
export const loadClevertap = (id, callback) => {
  window.clevertap = {
    event: [],
    profile: [],
    account: [],
    onUserLogin: [],
    notifications: [],
    privacy: [],
  };
  window.clevertap.account.push({ id: id });
  window.clevertap.privacy.push({ optOut: false });
  window.clevertap.privacy.push({ useIP: false });
  (function () {
    const wzrk = document.createElement("script");
    wzrk.type = "text/javascript";
    wzrk.async = true;
    wzrk.src = `${
      document.location.protocol === "https:"
        ? "https://d2r1yp2w7bby2u.cloudfront.net"
        : "http://static.clevertap.com"
    }/js/a.js`;
    wzrk.onload = callback;
    wzrk.onerror = () => callback(false);
    const s = document.getElementsByTagName("script")[0];
    if (s && s.parentNode) {
      s.parentNode.insertBefore(wzrk, s);
    } else {
      console.error("Unable to find script parent node.");
      callback(false);
    }
  })();
};
