import appBaseUrl from "../../config";
const env = appBaseUrl();
export const EXTERNAL_URLS = {
  CAR_INSURANCE: env.i24_APP_URL + "car-insurance/",
  TWO_WHEELER_INSURANCE: env.i24_APP_URL + "two-wheeler-insurance/",
  CARD_PROTECT: env.i24_GROUP_APP_URL + "card-protect/",
  UPI_PROTECT: env.i24_GROUP_APP_URL + "upi-protect/",
  HEALTH_GUARD_PLUS: env.i24_GROUP_APP_URL + "health-guard/",
  CAR_ASSISTANCE: env.i24_GROUP_APP_URL + "tyre-key-secure/",
  MOBILE_SCREEN_SECURE: env.i24_GROUP_APP_URL + "fonesafe/",
  CAR_INSURANCE_PRODUCTS: env.i24_APP_URL + "car-insurance-products/",
  CONTACT: env.i24_APP_URL + "contact/",
  USER_LOGIN: env.i24_APP_URL + "user-login/",
  PRIVACY_POLICY:
    "https://cdn.cars24.com/prod/cms/2024/10/08/ebe1c51c-f9da-40f6-920a-1f892820c03b2eb21d0e-5000-45ae-9835-ee49e0b1f466final-privacy-policy.pdf",
  HEALTH_INSURANCE: env.i24_APP_URL + "health-plan/",
  MAHAKUMBH_PROMOTIONAL_BANNER: env.i24_GROUP_APP_URL + "kumbh-mela/",
};
