import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import dynamic from "next/dynamic";
import { useIntersection } from "@/utils/useIntersection";

const Image = dynamic(() => import("next/image"), {
  ssr: false,
  loading: () => <div className={styles.loader} />,
});

export interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
}
interface IDynamicImage {
  src: string | StaticImageData;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
  wrapperClassName?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
}

const DynamicImage: React.FC<IDynamicImage> = ({
  src,
  alt,
  className,
  width,
  wrapperClassName,
  onClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isInView = useIntersection(ref, { threshold: 0.1 });
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    if (isInView) setShouldLoad(true);
  }, [isInView]);

  return (
    <div ref={ref} className={wrapperClassName}>
      {shouldLoad && (
        <Image
          src={src}
          alt={alt}
          className={className}
          width={width}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default DynamicImage;
