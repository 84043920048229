import { IMotorInsuranceCardDetails } from "./types";
import CarImg from "../../../public/svg/car-insurance.svg";
import BikeImg from "../../../public/svg/bike-insurance.svg";
import HealthInsuranceImg from "../../../public/svg/health-insurance.svg";
import { EXTERNAL_URLS } from "@/constants/urls.constants";
import { AnalyticsEvents } from "@/constants/appAnalyticsConstants";
export const MOTOR_INSURANCE_CARD_DETAILS: IMotorInsuranceCardDetails[] = [
  {
    image: CarImg,
    title: "Car Insurance",
    description:
      "Buy car insurance within minutes with the lowest premiums, instant claims, and holistic coverage plans as per your needs.",
    highlights: ["₹15 lakh personal accident cover", "Cashless repairs"],
    cardType: "Car",
    cta: "Get Quotes",
    ctaLink: EXTERNAL_URLS.CAR_INSURANCE,
    eventName: AnalyticsEvents.homepage.get_car_quote_staticpage_card.name,
  },
  {
    image: BikeImg,
    title: "Bike Insurance",
    description:
      "Buy two-wheeler insurance with affordable premiums, cashless repairs, and add-on benefits.",
    highlights: ["₹15 lakh personal accident cover", "100% digital"],
    cardType: "Bike",
    cta: "Get Quotes",
    ctaLink: EXTERNAL_URLS.TWO_WHEELER_INSURANCE,
    eventName: AnalyticsEvents.homepage.get_bike_quote_staticpage_card.name,
  },
  // {
  //   image: HealthInsuranceImg,
  //   title: "Health insurance",
  //   description:
  //     "Buy two-wheeler insurance within minutes with affordable premiums, cashless repairs, and add-on benefits.",
  //   highlights: ["Up to ₹15 lakh personal accident cover", "Get insured fast"],
  //   cardType: "Health",
  //   cta: "Get Quotes",
  //   ctaLink: EXTERNAL_URLS.HEALTH_INSURANCE,
  //   eventName: AnalyticsEvents.homepage.get_health_quote_staticpage.name,
  // },
];
